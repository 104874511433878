







































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {VBtn, VCard, VCardText, VLayout, VRadio, VRadioGroup, VTextField, VTooltip} from 'vuetify/lib';
import VSwatches from 'vue-swatches';

import 'vue-swatches/dist/vue-swatches.css';
import Favorite from '@/components/Favorite.vue';
import RuleFilter from '@/components/RuleFilter.vue';
import Timeout = NodeJS.Timeout;

const debounce = <T extends (...args: any[]) => any>(
    callback: T,
    waitFor: number,
) => {
  let timeout: Timeout;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};

interface Check {
  favorited: boolean;
  included: boolean;
  excluded: boolean;
  color: string;
  tooltip: string;
  title: string;
  name: string;
}

@Component({
  components: {
    VCard,
    VCardText,
    VLayout,
    VRadioGroup,
    VRadio,
    VBtn,
    VSwatches,
    Favorite,
    RuleFilter,
    VTooltip,
    VTextField,
  },
})
export default class AccountsResultRules extends Vue {

  @Prop({default: []})
  public checks: Check[];

  @Prop({default: null})
  public specialTerms: string;


  public filteredChecks: Check[] = [];

  get searchIndicator() {
    if (this.isCalculating) {
      return '⟳ Filtrerer';
    } else if (this.searchQueryIsDirty) {
      return '... Skriver';
    } else {
      return '';
    }
  }

  private q: string = '';
  private searchQueryIsDirty = false;
  private isCalculating = false;

  private runFilterDebounced = debounce(this.doFilterChecks, 500);
  private showOnlyFavorites = true;

  private availableColors = [
    '#1FBC9C',
    '#1CA085',
    '#2ECC70',
    '#27AF60',
    '#3398DB',
    '#2980B9',
    '#A463BF',
    '#8E43AD',
    '#3D556E',
    '#222F3D',
    '#F2C511',
    '#F39C19',
    '#E84B3C',
    '#C0382B',
    '#DDE6E8',
    '#EEEEEE',
  ];

  private toggleShowOnlyFavorites() {
    this.showOnlyFavorites = !this.showOnlyFavorites;
  }

  @Watch('showOnlyFavorites', { immediate: true })
  private onShowOnlyFavoritesChanged() {
    this.runFilterDebounced();
  }
  @Watch('checks', { immediate: true })
  private onChecksChanged() {
    this.runFilterDebounced();
  }

  @Watch('q', { immediate: true })
  private onQueryChanged() {
    this.searchQueryIsDirty = true;
    this.runFilterDebounced();
  }

  private doFilterChecks() {
    this.isCalculating = true;

    const query = this.q || '';
    if (query !== '') {
      this.filteredChecks = this.checks
          .filter((c) => {
            const t = c.title || '';
            const n = c.name || '';
            return t.toLowerCase().includes(query.toLowerCase())
                || n.toLowerCase().includes(query.toLowerCase());
          });
    } else if (this.showOnlyFavorites) {
      this.filteredChecks = this.checks.filter((c) => c.favorited
          || c.included
          || c.excluded
          || c.color !== '#EEEEEE');
    } else {
      this.filteredChecks = this.checks;
    }
    this.isCalculating = false;
    this.searchQueryIsDirty = false;
  }

}
