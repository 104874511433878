









import Vue from 'vue';
import {VContainer, VFlex, VLayout, VTextField} from 'vuetify/lib';
import {Component, Prop, Watch} from 'vue-property-decorator';

function Debounce(wait: number, immediate: boolean = false) {
    return (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
        let timeout: any;
        const originalMethod = descriptor.value;
        descriptor.value = function() {
            const context = this;
            const args = arguments;
            const later = () => {
                timeout = null;
                if (!immediate) {
                    originalMethod.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                originalMethod.apply(context, args);
            }
        };
        return descriptor;
    };
}

@Component({
    components: {
        VTextField,
        VFlex,
        VLayout,
        VContainer,
    },
})
export default class AccountsSearch extends Vue {
    @Prop({default: ''})
    public query: string = '';
    public q: string = this.query;

    @Debounce(500)
    @Watch('q')
    public searchQueryChanged() {
        this.$emit('search', this.q);
    }

    public search() {
        this.$emit('search', this.q);
    }
}
