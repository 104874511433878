import Vue from 'vue';
import Vuex from 'vuex';
import {
    Account,
    AccountWithSelected,
    AdminPropertiesData,
    InvoiceData,
    ResultSearchOptions,
    SearchResponse,
} from '@/types';
import moment from 'moment';
import {InvoiceProduct} from '@/admin-client';
import {Map, Set} from 'immutable';


Vue.use(Vuex);


/*
Må bruke "X" + id fordi av og til tolkes id som tall og av og til som tekst. Dermed vil ikke === virke?

 */


interface State {
    user: any;
    response: SearchResponse;
    selected: Set<string>;
    invoiceDataMap: Map<string, InvoiceData>;
    adminPropertiesData: AdminPropertiesData;
    resultSearchOptions: ResultSearchOptions;
}

const defaultState: State = {
    user: null,
    response: {
        hits: [],
        total: 0,
        amount: 0,
        standardUpfrontPayment: 0,
        closed: {not_closed: 0, closed: 0},
        createdYear: {},
        createdYearMonth: {},
        expires: {},
        terms: {},
        tags: {},
        features: {},
        group: {},
    },
    selected: Set.of(),
    invoiceDataMap: Map(),
    adminPropertiesData: {
        favorites: Set.of(),
        colors: Map(),
        included: Set.of(),
        excluded: Set.of(),
        showOnlyFavorites: true,
    },
    resultSearchOptions: {
        terms: '',
        closed: 'not_closed',
        features: '',
        group: '',
        tags: '',
        expires: '',
        resultSetSize: 'small',
        createdYear: '',
        createdYearMonth: '',
    },
};


function _updateDescription(expires: string, employeesCount: number, specialTerms: string): string {
    let size = '16-30';
    if (employeesCount < 4) {
        size = '1-3';
    } else if (employeesCount < 9) {
        size = '4-8';
    } else if (employeesCount < 16) {
        size = '9-15';
    }
    let m = 'TrinnVis, årsabonnement, ';
    m += '' + size + ' personer, ';
    m += moment.utc(expires).add(1, 'day').format('DD.MM.YYYY');
    m += ' - ';
    m += moment.utc(expires).add(1, 'year').format('DD.MM.YYYY');
    if ('NFF' === specialTerms) {
        m += ' medlem NFF';
    }
    if ('DNLF' === specialTerms) {
        m += ' medlem Legeforeningen';
    }
    if ('NKF' === specialTerms) {
        m += ' medlem NKF';
    }
    if ('NTF' === specialTerms) {
        m += ' medlem NTF';
    }
    if ('NMF' === specialTerms) {
        m += ' medlem NMF';
    }
    if ('NPF' === specialTerms) {
        m += ' medlem NPF';
    }
    if ('NOF' === specialTerms) {
        m += ' medlem NOF';
    }
    if ('NNF' === specialTerms) {
        m += ' medlem NNF';
    }

    return m;
}

function _nextExpires(expires: string): string {
    return moment.utc(expires).add(1, 'year').format('YYYY-MM-DD');
}

function conditionallyAddProduct(products: InvoiceProduct[], line: string, amountAsText: string) {
    const regex = new RegExp('[^0-9-]', 'g');
    const amount = parseInt(('' + amountAsText).replace(regex, ''), 10);
    if (line !== '' || (+amount !== 0 && !isNaN(amount))) {
        products.push({description: line, amount});
    }
}

function invoiceDataForAccount(a: Account, invoiceDataMap: Map<string, InvoiceData>): InvoiceData {
    const id = a.id.toString();
    const s = invoiceDataMap.get('X' + id);
    if (s === undefined) {
        return {
            line1: _updateDescription(a.expires, a.employees.filter((e) => e.email === null || !e.email.endsWith('@helsekontor.no')).length, a.specialTerms),
            amount1: '' + a.amount,
            line2: '',
            amount2: '',
            line3: '',
            amount3: '',
            line4: '',
            amount4: '',
            nextExpires: _nextExpires(a.expires),
        };
    } else {
        return s;
    }
}

const store = new Vuex.Store<State>({
    state: defaultState,
    mutations: {
        setUser(state: State, user: any) {
            state.user = user;
        },
        setResponse(state: State, response: SearchResponse) {
            state.response = response;
        },
        selectNone(state: State) {
            state.selected = Set.of();
        },
        selectAll(state: State) {
            state.selected = Set.of(...state.response.hits.map((h: Account) => 'X' + h.id.toString()));
        },
        toggleSelect(state: State, id: string) {
            if (!state.selected.has('X' + id)) {
                state.selected = state.selected.add('X' + id);
            } else {
                state.selected = state.selected.delete('X' + id);
            }
        },
        updateInvoiceData(state: State, payload: { id: string, invoiceData: InvoiceData }) {
            state.invoiceDataMap = state.invoiceDataMap.set('X' + payload.id, payload.invoiceData);
        },
        clearInvoiceData(state: State, payload: { id: string }) {
            state.invoiceDataMap = state.invoiceDataMap.delete('X' + payload.id);
        },
        updateAdminPropertiesData(state: State, payload: AdminPropertiesData) {
            state.adminPropertiesData = payload;
        },
        updateResultSearchOptions(state: State, payload: ResultSearchOptions) {
            state.resultSearchOptions = payload;
        },
    },
    getters: {
        hasResponse: (state) => state.response !== null,
        accountsWithSelected: (state): AccountWithSelected[] =>
            state.response.hits.map((h) => {
                return ({
                    account: h,
                    selected: state.selected.has('X' + h.id.toString()),
                    invoiceData: invoiceDataForAccount(h, state.invoiceDataMap),
                });
            }),
    },
});

export default store;
