






























import Vue from 'vue';
import {VBtn, VDatePicker, VMenu, VSwitch, VTextField} from 'vuetify/lib';
import {Component, Prop, Watch} from 'vue-property-decorator';
import * as moment from 'moment';



@Component({
  components: {
    VTextField, VDatePicker, VMenu, VSwitch, VBtn,
  },
})
export default class AccountsResultStatus extends Vue {
  @Prop({default: {closed: false, expires: '2020-01-01' }})
  public account!: any;

  public date: string = '';
  public closedVal: boolean = false;

  public menu = false;



  public dateChanged(e: string) {
    this.menu = false;
    this.emitStatusChanged(e, this.account.closed);
  }

  public closedValChanged(val: boolean) {
    this.emitStatusChanged(this.date, val);
  }

  private emitStatusChanged(expires: string, closed: boolean) {
    this.$emit('statusChanged', {expires, closed});
  }

  private emitDelete() {
    this.$emit('delete', {});
  }

  @Watch('account', { immediate: true, deep: true })
  private onAccountChanges() {
    this.date = this.account.expires;
    this.closedVal = this.account.closed;
  }

  get dateFormatted() {
    return moment.utc(this.date).format('D.M.YYYY');
  }
}
