import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import { AmplifyPlugin, components, AmplifyEventBus } from 'aws-amplify-vue';
import Auth from '@aws-amplify/auth';
import AmplifyStore from './store';
import { Logger } from '@aws-amplify/core';
import { I18n } from '@aws-amplify/core';

Vue.use(Router);
Vue.use(AmplifyPlugin, { Auth, Logger, I18n });

let user1;

function getUser() {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data: any) => {
    if (data && data.signInUserSession) {
      AmplifyStore.commit('setUser', data);
      return data;
    }
  }).catch((e: any) => {
    AmplifyStore.commit('setUser', null);
    return null;
  });
}

const router =  new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true},
    },
    {
      path: '/auth',
      name: 'Authenticator',
      component: components.Authenticator,
    },
  ],
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    user1 = await getUser();
    if (!user1) {
      return next({
        path: '/auth',
        query: {
          redirect: to.fullPath,
        },
      });
    }
    return next();
  }
  return next();
});

getUser().then((user: any) => {
  if (user) {
    router.push({path: '/'});
  }
  return null;
});

AmplifyEventBus.$on('authState', async (state: any) => {
  if (state === 'signedOut') {
    user1 = null;
    AmplifyStore.commit('setUser', null);
    router.push({path: '/auth'});
  } else if (state === 'signedIn') {
    user1 = await getUser();
    router.push({path: '/'});
  }
});

export default router;
