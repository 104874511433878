












import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component({
})
export default class AccountsResultFacet extends Vue {
  @Prop({default: ''})
  public name: string;
  @Prop({default: '-'})
  public selected: string;
  @Prop({
    default: () => {
      return {};
    },
  })
  public bucket: { [key: string]: number };
  @Prop({
    default: () => {
      return {};
    },
  })
  public descriptions: { [key: string]: string };


  private groupVisible = false;
//  get groupVisible(): boolean {
//    return Object.keys(this.bucket) > 0;
//  }

  get facetValues(): any[] {
    const vm = this;
    return Object
        .keys(vm.bucket)
        .sort()
       // .filter((v) => vm.bucket[v] > 0)
        .map((a) => ({
          name: a,
          count: vm.bucket[a],
          description: vm.descriptions[a] ?? a,
          label: `${vm.descriptions[a] ?? a} (${vm.bucket[a]})`,
          displayText: vm.descriptions[a] ?? a,
        }));
  }

  private onChange(e: any) {
    if (Object.keys(this.bucket).length > 0) {
      const x = e === '-' ? '' : e;
      this.$emit('change', x);
    }
  }

  private onChangeSelect(e: any) {
    console.log( e.target.value)
    if (Object.keys(this.bucket).length > 0) {
      const x = e.target.value === '' ? '' : e.target.value;
      this.$emit('change', x);
    }
  }

  @Watch('bucket', { immediate: true, deep: true })
  private onBucketChanges() {
    const s = this;
    setTimeout(() => {
      s.groupVisible = true;
    }, 2000);
  }

}
