

















import Vue from 'vue';
import {VBtn, VIcon} from 'vuetify/lib';
import {Component, Prop} from 'vue-property-decorator';
import {AccountEmployee} from '@/types';


@Component({
    components: {
        VBtn, VIcon,
    },
})
export default class AccountsResultEmployee extends Vue {
    @Prop({default: {}})
    public employee: AccountEmployee;

    public dev = false;

    get access(): boolean {
        return this.employee.accessLevel !== 'NONE';
    }

    get localAccess(): boolean {
        return this.access && this.dev;
    }

    get accessDescription(): string {
        const e: {[key: string]: string} = {
            NONE: 'Ingen',
            READONLY: 'Lesetilgang',
            USER: 'Skrivetilgang',
        };
        return e[this.employee.accessLevel];
    }

    public loginUser() {
        this.$emit('loginUser', this.employee.email);
    }

    public localLoginUser() {
        this.$emit('localLoginUser', this.employee.email);
    }
}
