



























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {
    VBtn,
    VCard,
    VCardText,
    VCheckbox,
    VLayout,
    VRadio,
    VRadioGroup,
} from 'vuetify/lib';


@Component({
    components: {
        VCard,
        VCardText,
        VLayout,
        VRadioGroup,
        VRadio,
        VBtn,
    },
})
export default class AccountsResultHeader extends Vue {
    @Prop({default: 0})
    public total: number;
    @Prop({default: 0})
    public amount: number;
    @Prop({default: 'small'})
    public resultSetSize: string;

    @Prop({default: false})
    public showRules: boolean;



    public invoiceSelected() {
        this.$emit('invoiceSelected');
    }

    public selectAll() {
        this.$emit('selectAll');
    }

    public selectNone() {
        this.$emit('selectNone');
    }

    public toggleRules() {
        this.$emit('toggleRules');
    }

    private resultSetSizeChanged(e: any) {
        this.$emit('resultSetSizeChanged', e);
    }

}
