
































































































import AccountsResultItem from './AccountsResultItem.vue';
import AccountsResultFacet from './AccountsResultFacet.vue';
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import AccountsResultHeader from './AccountsResultHeader.vue';
import {InvoiceOptions, InvoiceProduct} from '@/admin-client';
import AccountsResultRules from './AccountsResultRules.vue';
import {Account, AccountWithSelected, InvoiceData, RuleStructure, SearchResponse} from '@/types';
import {Map, Set} from 'immutable';
import {VCard, VCardTitle} from "vuetify/lib";

function calculateMonthYearDescriptions(): { [key: string]: string } {
  const a = ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli',
    'august', 'september', 'oktober', 'november', 'desember'];
  const b = ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018',
    '2019', '2020', '2021', '2022', '2023', '2024', '2025'];
  const r: { [key: string]: string } = {};
  b.forEach((y) => {
    a.forEach((m, i) => {
      const key = `${y}-${(i + 1) < 10 ? '0' : ''}${i + 1}`;
      r[key] = `${m} ${y}`;
    });
  });
  return r;
}

function calculateYearDescriptions(): { [key: string]: string } {
  const b = ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018',
    '2019', '2020', '2021', '2022', '2023', '2024', '2025'];
  const r: { [key: string]: string } = {};
  b.forEach((y) => {
    const key = `${y}`;
    r[key] = `${y}`;
  });

  return r;
}

function h(v: Set<string>, key: string): boolean {
  return v.has(key);
}

@Component({
  components: {
    AccountsResultHeader,
    AccountsResultFacet,
    AccountsResultItem,
    AccountsResultRules,
    VCard,
    VCardTitle,
  },
})
export default class AccountsResult extends Vue {
  get accountsWithSelected(): AccountWithSelected[] {
    return this.$store.getters.accountsWithSelected;
  }

  get hits(): Account[] {
    return this.$store.state.response.hits;
  }

  get checks(): any[] {
    return this.rules.flatMap((r: RuleStructure) => r.checks.map((c) => {
      const key = r.title + '///' + c.name;
      return {
        title: r.title,
        name: c.name,
        description: r.title + ' ' + c.name,
        result: '' + c.percent + '% av ' + c.percentOf,
        filteredValues: c.filteredValues,
        favorited: h(this.favorites, key),
        color: this.colors.get(key) || '#EEEEEE',
        included: this.included.has(key),
        excluded: this.excluded.has(key),
        notes: c.notes,
        tooltip: AccountsResult.checkTooltip(c.aspects, this.termsDescriptions),
      };
    }));
  }

  get indicatorRules(): Array<{ description: string; color: string; filteredValues: number[]; favorited: boolean }> {
    return this.checks.filter((r) => {
      const key = r.title + '///' + r.name;
      return this.colors.has(key);
    }).map((r) => {
      return {
        description: r.title + '\n' + r.name,
        color: r.color,
        filteredValues: r.filteredValues,
        favorited: r.favorited,
        included: r.included,
        excluded: r.excluded,
        notes: r.notes,
      };
    });
  }


  get response(): SearchResponse {
    return this.$store.state.response;
  }

  get searchOptions() {
    return this.$store.state.resultSearchOptions;
  }

  get favorites(): Set<string> {
    return this.$store.state.adminPropertiesData.favorites;
  }

  get included(): Set<string> {
    return this.$store.state.adminPropertiesData.included;
  }

  get excluded(): Set<string> {
    return this.$store.state.adminPropertiesData.excluded;
  }

  get colors(): Map<string, string> {
    return this.$store.state.adminPropertiesData.colors;
  }

  private static conditionallyAddProduct(products: InvoiceProduct[], line: string, amountAsText: string) {
    const regex = new RegExp('[^0-9-]', 'g');
    const amount = parseInt(('' + amountAsText).replace(regex, ''), 10);
    if (line !== '' || (+amount !== 0 && !isNaN(amount))) {
      products.push({description: line, amount});
    }
  }

  private static checkTooltip(a: Array<{ column: string; value: string; percent: number; percentOf: number }>,
                              termsDescriptions: {
                                [key: string]: string;
                              }): string {

    const k = Object.keys(termsDescriptions);
    k.sort();
    const m: string[] = [];
    k.forEach((key) => {
      const d = termsDescriptions[key];
      const aspect = a.find((x) => x.value === key);
      if (aspect !== undefined) {
        const message = d + ' ' + aspect.percent + '% av ' + aspect.percentOf;
        m.push(message);
      } else {
        m.push(d);
      }
    });
    return m.join(', <br>');
  }

  private static invoiceBody(data: InvoiceData): InvoiceOptions {
    // tslint:disable-next-line:no-console
    console.log(data);
    const body = {
      expires: data.nextExpires,
      comment: '',
      products: [],
    };
    AccountsResult.conditionallyAddProduct(body.products, data.line1, data.amount1);
    AccountsResult.conditionallyAddProduct(body.products, data.line2, data.amount2);
    AccountsResult.conditionallyAddProduct(body.products, data.line3, data.amount3);
    AccountsResult.conditionallyAddProduct(body.products, data.line4, data.amount4);
    // tslint:disable-next-line:no-console
    console.log(body);
    return body;
  }

  @Prop({default: null})
  public rules: any;

  @Prop({default: false})
  public showRules: boolean;

  @Prop({default: () => []})
  public allTags: string[];

  public rulesVisible = false;

  public termsDescriptions = {
    NONE: 'Ingen',
    NFF: 'NFF',
    NOF: 'NOF',
    DNLF: 'Legeforeningen',
    NKF: 'NKF',
    NTF: 'NTF',
    NMF: 'NMF',
    NPF: 'NPF',
    NNF: 'NNF',
  };

  public groupDescriptions = {
    FASTLEGE: 'Fastlege',
    SPESIALIST: 'Spesialist',
    TANNLEGE: 'Tannlege',
    ANNET: 'Annet',
  };


  public closedDescriptions = {
    closed: 'Avsluttet',
    not_closed: 'Aktiv',
  };

  public tagsDescriptions = {};

  public featuresDescriptions = {
    MINI: "Mini",
    BASIC: "Basis",
    EXTRA: "Ekstra",
  };

  public monthYearDescriptions = calculateMonthYearDescriptions();
  public yearDescriptions = calculateYearDescriptions();

  public selectAll() {
    this.$store.commit('selectAll');
  }

  public selectNone() {
    this.$store.commit('selectNone');
  }

  public selectedChanged(e: any) {
    this.$store.commit('toggleSelect', e.id);
  }

  public invoiceSelected() {
    const r: any[] = [];
    this.accountsWithSelected
        .filter((e) => e.selected)
        .forEach((e) => {
          r.push({id: e.account.id, account: e.account, invoiceData: AccountsResult.invoiceBody(e.invoiceData)});
        });
    this.$store.commit('selectNone');
    this.$emit('invoiceSelected', r);
  }

  public updateAccountPlan(e: any) {
    this.$emit('updateAccountPlan', e);
  }

  public statusChanged(e: any) {
    this.$store.commit('clearInvoiceData', {id: e.id});
    this.$emit('accountStatusChanged', e);
  }

  public deleteAccount(e: any) {
    this.$emit('deleteAccount', e);
  }

  public tags(e: any) {
    this.$emit('tags', e);
  }

  public invoiceChanged(e: any) {
    this.$store.commit('updateInvoiceData', {id: e.id, invoiceData: e.invoiceData});
  }

  public loginUser(e: any): any {
    this.$emit('loginUser', e);
  }

  private selectColor(e: any) {
    const key = e.title + '///' + e.name;
    this.$emit('selectColor', {key, color: e.color});
  }

  private updateFilter(e: any) {
    const key = e.title + '///' + e.name;
    this.$emit('updateFilter', {key, include: e.include, exclude: e.exclude});
  }

  private clearSelections() {
    this.$emit('clearSelections', {});
  }

  private toggleFavoriteOn(e: any) {
    const key = e.title + '///' + e.name;
    this.$emit('addFavorite', {key});
  }

  private toggleFavoriteOff(e: any) {
    const key = e.title + '///' + e.name;
    this.$emit('deleteFavorite', {key});
  }

  private resultSetSizeChanged(e: any) {
    this.$emit('resultSetSizeChanged', e);
  }
}
