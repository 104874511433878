

















import Vue from 'vue';
import { VBtn, VIcon, VTooltip } from 'vuetify/lib';
import { Component, Prop } from 'vue-property-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye as regularEye, faEyeSlash as regularEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faEye as solidEye, faEyeSlash as solidEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(regularEye);
library.add(regularEyeSlash);
library.add(solidEye);
library.add(solidEyeSlash);

@Component({
    components: {
        VBtn, VIcon, VTooltip, FontAwesomeIcon,
    },
})
export default class RuleFilter extends Vue {

    // if color is '' then this indicator is off

    @Prop({default: false})
    public included: boolean;
    @Prop({default: false})
    public excluded: boolean;

}
