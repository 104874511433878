











import Vue from 'vue';
import {VBtn, VIcon, VTooltip} from 'vuetify/lib';
import {Component, Prop} from 'vue-property-decorator';


@Component({
    components: {
        VBtn, VIcon, VTooltip,
    },
})
export default class AccountsResultIndicators extends Vue {

    // if color is '' then this indicator is off

    @Prop({default: null})
    public indicators: Array<{ description: string; color: string; filteredValues: number[] }>;

    @Prop({default: null})
    public organizationId: number;

    public indicatorColor(color: string, id: number, filteredValues: number[]) {
        return filteredValues.includes(id) ? color : '#EEEEEE';
    }
}
