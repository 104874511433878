











import Vue from 'vue';
import {VBtn, VIcon, VTooltip} from 'vuetify/lib';
import {Component, Prop} from 'vue-property-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar as x } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(x);
library.add(faStar);

@Component({
    components: {
        VBtn, VIcon, VTooltip, FontAwesomeIcon,
    },
})
export default class Favorite extends Vue {

    // if color is '' then this indicator is off

    @Prop({default: false})
    public favorited: boolean;

}
