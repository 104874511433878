















import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';


@Component({
  components: {
  },
})
export default class AccountsResultComments extends Vue {
  @Prop({default: []})
  public comments: [any];
}
