// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Amplify from '@aws-amplify/core';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import App from './App.vue';

import store from './store';
import router from './router';

import VSwatches from 'vue-swatches';

import vuetify from '@/plugins/vuetify';

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_yFhMkb6Th',
    userPoolWebClientId: '1r39p8kb2h7it52ko62uhvfs5r',
    mandatorySignIn: true,
  },
});




Vue.config.productionTip = false;
Vue.component('v-swatches', VSwatches);

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
