
































import AccountsSearch from './AccountsSearch.vue';
import AccountsResult from './AccountsResult.vue';
import {Component, Prop} from 'vue-property-decorator';

import Vue from 'vue';
import {SearchOptions} from '@/admin-client';

@Component({
  components: {
    AccountsSearch,
    AccountsResult,
  },
})
export default class AccountsForm extends Vue {
  @Prop({default: ''})
  public token!: string;

  @Prop({default: null})
  public rules: any;

  @Prop({default: null})
  public adminProperties: any;

  @Prop({default: []})
  public allTags: string[];

  @Prop({default: false})
  public showRules: boolean;

  get hasResponse(): boolean {
    return this.$store.getters.hasResponse;
  }

  private get favorites() {
    return this.adminProperties.favorites;
  }

  private get included() {
    return this.adminProperties.included;
  }

  private get excluded() {
    return this.adminProperties.excluded;
  }

  private get colors() {
    return this.adminProperties.colors;
  }

  public query: string = '';
  public q2: string = '';

  get searchOptions() {
    return this.$store.state.resultSearchOptions;
  }

  public e = null;

  public termsChanged(e: string) {
    this.$store.commit('updateResultSearchOptions', {...this.searchOptions, terms: e});
    this.search(this.q2);
  }

  public closedChanged(e: string) {
    this.$store.commit('updateResultSearchOptions', {...this.searchOptions, closed: e});
    this.search(this.q2);
  }

  public groupChanged(e: string) {
    this.$store.commit('updateResultSearchOptions', {...this.searchOptions, group: e});
    this.search(this.q2);
  }

  public featuresChanged(e: string) {
    this.$store.commit('updateResultSearchOptions', {...this.searchOptions, features: e});
    this.search(this.q2);
  }
  public tagsChanged(e: string) {
    this.$store.commit('updateResultSearchOptions', {...this.searchOptions, tags: e});
    this.search(this.q2);
  }

  public expiresChanged(e: string) {
    this.$store.commit('updateResultSearchOptions', {...this.searchOptions, expires: e});
    this.search(this.q2);
  }

  public createdYearChanged(e: string) {
    this.$store.commit('updateResultSearchOptions', {...this.searchOptions, createdYear: e, createdYearMonth: ''});
    this.search(this.q2);
  }

  public createdYearMonthChanged(e: string) {
    this.$store.commit('updateResultSearchOptions', {...this.searchOptions, createdYearMonth: e});
    this.search(this.q2);
  }

  public resultSetSizeChanged(e: string) {
    this.$store.commit('updateResultSearchOptions', {...this.searchOptions, resultSetSize: e});
    this.search(this.q2);
  }

  public search(q: string) {
    this.q2 = q == null ? '' : q;
    this.$emit('search', this.currentSearchOptions());
  }

  public loginUser(e: any): any {
    this.$emit('loginUser', e);
  }

  public updateAccountPlan(e: any) {
    this.$emit('updateAccountPlan', { ...e, searchOptions: this.currentSearchOptions() });
  }

  public accountStatusChanged(e: any): any {
    const event = {
      id: e.id,
      message: e.message,
      searchOptions: this.currentSearchOptions(),
    };
    this.$emit('accountStatusChanged', event);
  }

  public deleteAccount(e: any): any {
    const event = {
      id: e.id,
      username: e.username,
      searchOptions: this.currentSearchOptions(),
    };
    this.$emit('deleteAccount', event);
  }

  public invoiceSelected(r: any) {
    this.$emit('invoiceSelected', {items: r, searchOptions: this.currentSearchOptions()});
  }

  private currentSearchOptions(): SearchOptions {
    function toBooleanString(value: string, trueValue: string, falseValue: string): string {
      if (value === trueValue) {
        return 'true';
      }
      if (value === falseValue) {
        return 'false';
      }
      return '';
    }

    const resultSetSizes: { [key: string]: number } = {
      small: 10,
      medium: 25,
      large: 100,
      xlarge: 200,
    };
    return {
      q: this.q2.trim(),
      size: resultSetSizes[this.searchOptions.resultSetSize],
      from: 0,
      terms: this.searchOptions.terms,
      features: this.searchOptions.features,
      tags: this.searchOptions.tags,

      closed: toBooleanString(this.searchOptions.closed, 'closed', 'not_closed'),
      group: this.searchOptions.group,
      expiresMonth: this.searchOptions.expires,
      createdYear: this.searchOptions.createdYear,
      createdYearMonth: this.searchOptions.createdYearMonth,

    };
  }
}
