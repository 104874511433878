import ApiClient from './client';
import {RuleStructure} from '@/types';
import {AxiosResponse} from 'axios';
import {Set} from 'immutable';

const client = new ApiClient('https://api.trinnvis.io');
// const client = new ApiClient('http://localhost:8080');


export interface SearchOptions {
    q: string;
    size: number;
    from: number;
    terms: string;

    closed: string;
    group: string;
    features: string;
    tags: string;
    expiresMonth: string;
    createdYear: string;
    createdYearMonth: string;
}

export interface LoginOptions {
    userEmail: string;
    id: string;
}

export interface AccountStatusOptions {
    expires: string;
    closed: boolean;
}

export interface AccountPlanUpdateMessage {
    invoicePlan: string;
    featurePlan: string;
}
export interface AccountPlanUpdateMessageAddFeature {
    addFeature: string;
}
export interface AccountPlanUpdateMessageRemoveFeature {
    removeFeature: string;
}

export interface InvoiceProduct {
    description: string;
    amount: number;
}


export interface InvoiceOptions {
    expires: string;
    comment: string;
    products: InvoiceProduct[];
}

export interface AccountTagsOptions {
    tags: string[];
}

export interface AdminPropertiesDataResponse {
    favorites: string[];
    colors: { [key: string]: string };
    included: string[];
    excluded: string[];
    showOnlyFavorites: boolean;
}


export default {

    search(searchOptions: SearchOptions, included: Set<string>, excluded: Set<string>): any {
        const p = {
            q: searchOptions.q,
            size: searchOptions.size,
            from: searchOptions.from,
            terms: searchOptions.terms,
            closed: searchOptions.closed,
            expiresMonth: searchOptions.expiresMonth,
            createdYear: searchOptions.createdYear,
            features: searchOptions.features,
            tags: searchOptions.tags,
            group: searchOptions.group,
            createdYearMonth: searchOptions.createdYearMonth,
            included: Array.from(included.values()),
            excluded: Array.from(excluded.values()),
        };
        return client.get('/accounts/search', {params: p});
    },


    reindex(): any {
        const command = {}

        return client.post('/accounts/:reindex', command);
    },

    login(loginOptions: LoginOptions): any {
        return client.get('/accounts/login', {params: loginOptions});
    },
    updateAccountPlan(accountId: number, data: AccountPlanUpdateMessage): any {
        return client.post(`/accounts/${accountId}/:update-account-plan`, data);
    },
    updateAccountPlanAddFeature(accountId: number, data: AccountPlanUpdateMessageAddFeature): any {
        return client.post(`/accounts/${accountId}/:update-account-plan`, data);
    },
    updateAccountPlanRemoveFeature(accountId: number, data: AccountPlanUpdateMessageRemoveFeature): any {
        return client.post(`/accounts/${accountId}/:update-account-plan`, data);
    },
    updateStatus(accountId: number, data: AccountStatusOptions): any {
        return client.put(`/accounts/${accountId}/status`, data);
    },
    tags(accountId: number, data: AccountTagsOptions): any {
        return client.put(`/accounts/${accountId}/properties`, data);
    },
    deleteAccount(accountId: number, username: string): any {
        return client.delete(`/accounts/${accountId}?username=${username}`);
    },

    invoiceAccount(accountId: number, data: InvoiceOptions): any {
        return client.post(`/accounts/${accountId}/invoice`, data);
    },

    rules(): Promise<AxiosResponse<RuleStructure[]>> {
        return client.get(`/accounts/admin-reports/rules`);
    },
    allTags(): Promise<AxiosResponse<string[]>> {
        return client.get(`/accounts/admin-all-tags`);
    },
    adminProperties(): Promise<AxiosResponse<AdminPropertiesDataResponse>> {
        return client.get(`/accounts/admin-properties`);
    },
    updateProperty(key: string, data: any): any {
        const d: { [k: string]: any } = {};
        d[key] = data;
        return client.put(`/accounts/admin-properties`, d);
    },

};
