import axios, {AxiosInstance} from 'axios';
import Auth from '@aws-amplify/auth';

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl: string ) => {
  const options = {
    baseURL: baseUrl,
  };

  const client = axios.create(options);

  client.interceptors.request.use((config) => Auth.currentSession()
    .then((session) => {
      // User is logged in. Set auth header on all requests
      config.headers.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;
      return Promise.resolve(config);
    })
    .catch(() => Promise.resolve(config)));

  // Add a request interceptor
  client.interceptors.request.use(
    (requestConfig) => requestConfig,
    (requestError) => Promise.reject(requestError),
  );

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status >= 500) {
        // Raven.captureException(error);
      }
      return Promise.reject(error);
    },
  );

  return client;
};

class ApiClient {
  private client: AxiosInstance;
  constructor(baseUrl: string) {
    this.client = getClient(baseUrl);
  }

  public get<T = any>(url: string, conf = {}) {
    return this.client.get<T>(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  public put(url: string, data = {}, conf = {}) {
    return this.client.put(url, data, conf)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
  }

  public delete(url: string, conf = {}) {
    return this.client.delete(url, conf)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
  }

  public post(url: string, data = {}, conf = {}) {
    return this.client.post(url, data, conf)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
  }
}

export default ApiClient;
